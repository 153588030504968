<template>
  <v-app>
    <v-container class="main-container scroll-height">
      <Header />
      <v-row no-gutters>
        <router-view @lring="loadRing" @ring="doRing" />
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import Header from "./components/layout/Header";

const ringtone = require("@/assets/calling_ringtone.mp3");

export default {
  name: "App",

  components: {
    Header,
  },

  data: () => ({
    ringtone:ringtone,
    rtoneAudio:null,
  }),

  methods: {
    doRing(on) {
      if (this.rtoneAudio) {
        if (on) {
          this.rtoneAudio.play();
        }
        else {
          this.rtoneAudio.pause();
        }
      }
    },

    loadRing() {
      if (this.rtoneAudio) {
        return;
      }
      //var self = this;
      this.rtoneAudio = new Audio(this.ringtone);
      this.rtoneAudio.addEventListener("canplaythrough", () => {
        //self.rtoneAudio.play();
      });
      this.rtoneAudio.addEventListener("error", (e) => {
        console.log('error',e);
      });
      this.rtoneAudio.loop = true;
      this.rtoneAudio.load();
    }
  },
};
</script>

<style lang="scss">
@import "@/sass/_mixins.scss";
@import "@/sass/_common.scss";

.scroll-height {
  height: 100vh;
  overflow-y: scroll;
}

.main-container {
  padding: 0;
  border: 0;
  border-radius: 2%;

  // Camera
  &::after {
    content: "";
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    height: 10px;
    width: 10px;
    background-color: dimgray;
    border-radius: 50%;
    display: none;
  }

  @include sm {
    padding: 0;
    border: 40px solid #9e9e9e;

    // Camera
    &::after {
      display: block;
      top: 15px;
    }
  }

  @include md {
    padding: 0;
    border: 60px solid #9e9e9e;

    // Camera
    &::after {
      display: block;
      top: 23px;
    }
  }

  @include lg-up {
    padding: 0;
    border: 80px solid #9e9e9e;

    // Camera
    &::after {
      display: block;
      top: 33px;
    }
  }
}

.container {
  @include lg-up {
    max-width: 1185px;
  }
}
</style>
